import { useEffect } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProtectedRoute } from '../components';
import Unauthorized from '../pages/Unauthorized';
import routeList from './config';
import { setGlobalState } from '../store/globalReducer';
import { useDispatch } from 'react-redux';

const RouteComponentWrapper = ({ title, requiresAuth, ...properties }) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t(title);
        if (location.pathname.includes('assessments/manage')) {
            dispatch(setGlobalState({ currentPage: 'Assessment' }));
            localStorage.setItem('currentPage', 'Assessment');
        }
        if (location.pathname.includes('assessments/internal')) {
            dispatch(setGlobalState({ currentPage: 'Internal' }));
            localStorage.setItem('currentPage', 'Internal');
        }
        if (location.pathname.includes('assessments/risk')) {
            dispatch(setGlobalState({ currentPage: 'Risk' }));
            localStorage.setItem('currentPage', 'Risk');
        }
        if (location.pathname.includes('assessments/policy')) {
            dispatch(setGlobalState({ currentPage: 'Policy' }));
            localStorage.setItem('currentPage', 'Policy');
        }
    }, [location]);

    const { element } = properties;

    return requiresAuth ? (
        <ProtectedRoute
            element={element}
            {...properties}
        />
    ) : (
        element
    );
};

const checkPermissions = (permission, permissions, element) => {
    return permission && !permissions?.includes(permission) ? <Unauthorized /> : element;
};

const parseRoutes = (routes, permissions) => {
    return routes.map((route) => {
        const convertedRoute = {
            path: route.path,
            element: (
                <RouteComponentWrapper
                    title={route.name}
                    element={checkPermissions(route.permission, permissions, route.element)}
                    requiresAuth={false} //TODO: Set properly
                />
            )
        };

        if (route.children) {
            convertedRoute.children = parseRoutes(route.children, permissions);
        }

        return convertedRoute;
    });
};

const RenderRouter = ({ permissions }) => {
    const routes = parseRoutes(routeList, permissions);

    const element = useRoutes(routes);

    return element;
};

export default RenderRouter;
