/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

const initialState = {
    notifications: []
};

export const notificationType = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
    BELL: 'bell'
};

const isValidNotificationType = (type) => Object.values(notificationType).includes(type);

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification(state, action) {
            if (action?.payload?.jobId) {
                const index = state.notifications.findIndex((n) => n.jobId === action.payload.jobId);
                if (index !== -1) {
                    state.notifications[index] = { ...state.notifications[index], ...action.payload };
                    return;
                }
            } else if (action?.payload?.scanId) {
                const index = state.notifications.findIndex((n) => n.scanId === action.payload.scanId);
                if (index !== -1) {
                    state.notifications[index] = { ...state.notifications[index], ...action.payload };
                    return;
                }
            }

            const id = nanoid();
            const now = Date.now();
            const defaultTimeout = action.payload.type === 'bell' ? Number.POSITIVE_INFINITY : 5000;
            const notificationTypeValue = action.payload.type && isValidNotificationType(action.payload.type)
                ? action.payload.type
                : notificationType.INFO;
            state.notifications.unshift({
                ...action.payload,
                id,
                seen: false,
                interacted: false,
                timeout: action.payload.timeout ?? now + defaultTimeout,
                type: notificationTypeValue
            });
        },
        seenNotification: (state, action) => {
            state.notifications = state.notifications.map((notification) =>
                notification.id === action.payload
                    ? {
                        ...notification,
                        seen: true
                    }
                    : notification
            );
        },
        markSeenAllNotifications: (state) => {
            state.notifications = state.notifications.map((notification) => ({
                ...notification,
                seen: true
            }));
        },
        interactedWithNotification: (state, action) => {
            state.notifications = state.notifications.map((notification) =>
                notification.id === action.payload
                    ? {
                        ...notification,
                        seen: true,
                        interacted: true
                    }
                    : notification
            );
        },
        updateNotificationProgress: (state, action) => {
            const { jobId, progress } = action.payload;
            state.notifications = state.notifications.map((notification) =>
                notification.jobId === jobId
                    ? {
                        ...notification,
                        progress
                    }
                    : notification
            );
        },
        clearExpiredNotifications: (state) => {
            const now = Date.now();
            state.notifications = state.notifications.filter(
                (notification) =>
                    notification.type === notificationType.BELL ||
                    (notification.seen && notification.interacted && notification.timeout >= now)
            );
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter((notification) => notification.id !== action.payload);
        },
        clearNotifications: () => initialState
    }
});

export const {
    addNotification,
    seenNotification,
    markSeenAllNotifications,
    interactedWithNotification,
    clearExpiredNotifications,
    removeNotification,
    clearNotifications,
    updateNotificationProgress
} = notificationsSlice.actions;

export default notificationsSlice.reducer;
